import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Auth, AuthResponse } from '../_models/auth';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../_models/core';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private httpClient: HttpClient) {}

  login(body: Auth): Observable<AuthResponse> {
    return this.httpClient.post<AuthResponse>(
      `${environment.apiUrl}admin/login`,
      body
    );
  }

  getSiteSettings(): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(
      `${environment.apiUrl}admin/getSiteSettings`
    );
  }
  UpdateSiteSettings(body): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${environment.apiUrl}admin/UpdateSiteSettings`,
      body
    );
  }
  getBankDetails(): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(
      `${environment.apiUrl}admin/getBankDetails`
    );
  }
  updateBankDetails(body): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${environment.apiUrl}admin/updateBankDetails`,
      body
    );
  }
  UploadImage(body: any, sizeFile,type): Observable<AuthResponse> {
    return this.httpClient.post<AuthResponse>(
      `${environment.apiUrl}admin/fileUpload?sizeFile=${sizeFile}&&type=${type}`,
      body
    );
  }
  getDashboardCount(): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(
      `${environment.apiUrl}admin/getDashboardCount`
    );
  }
  getMyProfile(): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(
      `${environment.apiUrl}admin/getMyProfile`
    );
  }
  updateMyProfile(body): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${environment.apiUrl}admin/updateMyProfile`,
      body
    );
  }
  changePassword(body): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${environment.apiUrl}admin/changePassword`,
      body
    );
  }
  sendNewsLetter(body): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(
      `${environment.apiUrl}admin/sendNewsLetter`,
      body
    );
  }
}
